import React from 'react';
import { LuClock4 } from 'react-icons/lu';
import styles from '../styles/MobileExcerptItem.module.css';
import Tags from './Tags';

const MobileExcerptItem = ({ excerpt, onTagSelect, onListenExcerpt, isPlaying, useShortSummary = false }) => {
  return (
    <li className={styles.resultItem}>
      <div className={styles.contentWrapper}>
        <h3 
          className={`${styles.resultText} ${isPlaying ? styles.resultTextPlaying : ''}`} 
          onClick={() => onListenExcerpt(excerpt)}
        >
          {excerpt.title}
        </h3>
        <div className={styles.metadataWrapper}>
          <span className={styles.iconWrapper}>
            <LuClock4 size={16} />
            {excerpt.excerpt_length}
          </span>
          <span className={styles.summaryWrapper}>
            <span className={styles.summaryText}>
              {useShortSummary ? excerpt.summary_short || excerpt.summary || "No summary available" : excerpt.summary || "No summary available"}
            </span>
          </span>
          <Tags tags={excerpt.tags} onTagSelect={onTagSelect} />
        </div>
      </div>
    </li>
  );
};

export default MobileExcerptItem;
