import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllShows } from '../api/api';

export const fetchAllShows = createAsyncThunk(
  'shows/fetchAllShows',
  async (params = {}, { getState }) => {
    const { shows } = getState();
    
    // If it's a new search or first page, use provided params
    // Otherwise, append to existing data
    const isNewSearch = params.query !== shows.currentQuery || params.page === 1;
    
    const response = await getAllShows({
      query: params.query,
      page: params.page || 1,
      limit: params.limit || 10
    });

    return {
      data: response,
      isNewSearch,
    };
  }
);

const allShowsSlice = createSlice({
  name: 'shows',
  initialState: {
    data: null,
    loading: false,
    error: null,
    currentPage: 1,
    hasMore: true,
    currentQuery: '',
  },
  reducers: {
    clearShowsData: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
      state.currentPage = 1;
      state.hasMore = true;
      state.currentQuery = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllShows.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllShows.fulfilled, (state, action) => {
        // Initialize state.data if it's null
        if (!state.data) {
          state.data = { shows: [] };
        }

        // If it's the first page, replace the shows
        // If it's not the first page, concatenate the new shows
        if (action.payload.data.pagination.page === 1) {
          state.data.shows = action.payload.data.shows;
        } else {
          state.data.shows = [...state.data.shows, ...action.payload.data.shows];
        }
        
        state.data.pagination = action.payload.data.pagination;
        state.data.total_count = action.payload.data.total_count;
        // Calculate hasMore based on total_count and current items
        state.hasMore = state.data.shows.length < action.payload.data.total_count;
        state.loading = false;
        state.currentQuery = action.payload.data.query || '';
      })
      .addCase(fetchAllShows.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearShowsData } = allShowsSlice.actions;

export default allShowsSlice.reducer;
