import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { fetchExploreResults, clearExploreData } from '../store/exploreSlice';
import ExcerptsTable from './ExcerptsTable';
import ExcerptPlayer from './ExcerptPlayer';
import LoadingSpinner from './LoadingSpinner';
import AuthenticationSpinner from './AuthenticationSpinner';
import PageHelmet from './PageHelmet';
import styles from '../styles/ExplorePage.module.css';
import ShowFilterModal from './ShowFilterModal';
import ShowImage from './ShowImage';
import TopicsList from './TopicsList';
import Pagination from './Pagination';
import PersonFilterModal from './PersonFilterModal';
import { useNavigate, useLocation, useSearchParams, Link } from 'react-router-dom';
import { fetchShowByUUID } from '../store/showInfoSlice';
import { fetchPersonByUUID } from '../store/personInfoSlice';
import { fetchTagByUUID } from '../store/tagInfoSlice';
import PersonImage from './PersonImage';

const ExplorePage = () => {
  const [selectedExcerpt, setSelectedExcerpt] = useState(null);
  const [currentExcerptIndex, setCurrentExcerptIndex] = useState(null);
  const [overlayRoot, setOverlayRoot] = useState(null);
  const [showFilterModalOpen, setShowFilterModalOpen] = useState(false);
  const [selectedShows, setSelectedShows] = useState([]);
  const [selectedShowsDetails, setSelectedShowsDetails] = useState({});
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedTopicsDetails, setSelectedTopicsDetails] = useState({});
  const [selectedPersons, setSelectedPersons] = useState([]);
  const [selectedPersonsDetails, setSelectedPersonsDetails] = useState({});
  const [personFilterModalOpen, setPersonFilterModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { data, status, error, currentPage, hasMore } = useSelector((state) => state.explore);
  const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Loading Explore...');

  useEffect(() => {
    setOverlayRoot(document.getElementById('overlay-root'));
    return () => {
      dispatch(clearExploreData());
    };
  }, [dispatch]);

  useEffect(() => {
    const loadInitialFilters = async () => {
      const showsUuid = searchParams.get('shows_uuid');
      const personsUuid = searchParams.get('persons_uuid');
      const tagsUuid = searchParams.get('tags_uuid');
      
      setInitialLoading(true);
      try {
        if (showsUuid) {
          setLoadingMessage('Exploring Shows...');
          const showResponse = await dispatch(fetchShowByUUID(showsUuid)).unwrap();
          if (showResponse?.show) {
            handleShowSelect(showResponse.show);
          }
        }

        if (personsUuid) {
          setLoadingMessage('Exploring People...');
          const personResponse = await dispatch(fetchPersonByUUID(personsUuid)).unwrap();
          if (personResponse?.person) {
            handlePersonSelect(personResponse.person);
          }
        }

        if (tagsUuid) {
          setLoadingMessage('Exploring Topics...');
          const tagResponse = await dispatch(fetchTagByUUID(tagsUuid)).unwrap();
          if (tagResponse) {
            handleTopicSelect(tagResponse.tag.name, tagResponse.tag);
          }
        }
      } catch (error) {
        console.error('Error loading initial filters:', error);
      } finally {
        setInitialLoading(false);
        setInitialLoadComplete(true);
      }
    };

    if (isAuthenticated && !initialLoadComplete) {
      loadInitialFilters();
    }
  }, [isAuthenticated, searchParams, dispatch, initialLoadComplete]);

  useEffect(() => {
    if (!authLoading) {
      if (isAuthenticated && initialLoadComplete) {
        dispatch(fetchExploreResults({ 
          page: 1,
          limit: 10,
          shows: selectedShows,
          topics: selectedTopics,
          persons: selectedPersons
        }));
      } else if (!isAuthenticated) {
        localStorage.setItem('redirectAfterAuth', location.pathname + location.search);
        navigate('/auth?from=' + encodeURIComponent(location.pathname + location.search));
      }
    }
  }, [selectedShows, selectedTopics, selectedPersons, dispatch, authLoading, isAuthenticated, navigate, location, initialLoadComplete]);

  const handlePageChange = (newPage) => {
    dispatch(fetchExploreResults({ 
      page: newPage,
      limit: 10,
      shows: selectedShows,
      topics: selectedTopics,
      persons: selectedPersons
    }));
  };

  const handleSelectExcerpt = (excerpt, index) => {
    setSelectedExcerpt(excerpt);
    setCurrentExcerptIndex(index);
  };

  const handleCloseExcerpt = () => {
    setSelectedExcerpt(null);
    setCurrentExcerptIndex(null);
  };

  const goToNextExcerpt = () => {
    if (data?.excerpts && currentExcerptIndex < data.excerpts.length - 1) {
      const nextIndex = currentExcerptIndex + 1;
      setCurrentExcerptIndex(nextIndex);
      setSelectedExcerpt(data.excerpts[nextIndex]);
    }
  };

  const goToPreviousExcerpt = () => {
    if (currentExcerptIndex > 0) {
      const prevIndex = currentExcerptIndex - 1;
      setCurrentExcerptIndex(prevIndex);
      setSelectedExcerpt(data.excerpts[prevIndex]);
    }
  };

  const handleShowSelect = (show) => {
    setSelectedShows(prev => {
      const isAlreadySelected = prev.includes(show.shows_uuid);
      
      if (!isAlreadySelected) {
        setSelectedShowsDetails(prev => ({
          ...prev,
          [show.shows_uuid]: show
        }));
      }
      
      return isAlreadySelected 
        ? prev.filter(uuid => uuid !== show.shows_uuid)
        : [...prev, show.shows_uuid];
    });
  };

  const renderOverlay = () => {
    if (!selectedExcerpt || !overlayRoot) return null;

    return createPortal(
      <div 
        className={styles.overlay} 
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            handleCloseExcerpt();
          }
        }}
      >
        <div className={styles.overlayContent}>
          <ExcerptPlayer
            excerpt={selectedExcerpt}
            startPlaying={true}
            onAudioEnded={goToNextExcerpt}
            onNextExcerpt={goToNextExcerpt}
            onPreviousExcerpt={goToPreviousExcerpt}
          />
          <button className={styles.closeButton} onClick={handleCloseExcerpt}>
            ×
          </button>
        </div>
      </div>,
      overlayRoot
    );
  };

  const getErrorMessage = (error) => {
    if (typeof error === 'string') return error;
    if (error?.message) return error.message;
    if (error?.error) return error.error;
    return 'An unexpected error occurred';
  };

  const getSelectedShowDetails = (showUuid) => {
    return selectedShowsDetails[showUuid];
  };

  const handleTopicSelect = (topicName, topic) => {
    setSelectedTopics(prev => {
      const isAlreadySelected = prev.includes(topic.tags_uuid);
      
      if (!isAlreadySelected) {
        setSelectedTopicsDetails(prev => ({
          ...prev,
          [topic.tags_uuid]: topic
        }));
      }
      
      return isAlreadySelected 
        ? prev.filter(uuid => uuid !== topic.tags_uuid)
        : [...prev, topic.tags_uuid];
    });
  };

  const getSelectedTopicDetails = (topicUuid) => {
    return selectedTopicsDetails[topicUuid];
  };

  const handlePersonSelect = (person) => {
    setSelectedPersons(prev => {
      const isAlreadySelected = prev.includes(person.persons_uuid);
      
      if (!isAlreadySelected) {
        setSelectedPersonsDetails(prev => ({
          ...prev,
          [person.persons_uuid]: person
        }));
      }
      
      return isAlreadySelected 
        ? prev.filter(uuid => uuid !== person.persons_uuid)
        : [...prev, person.persons_uuid];
    });
  };

  const getSelectedPersonDetails = (personUuid) => {
    return selectedPersonsDetails[personUuid];
  };

  if (authLoading) {
    return <AuthenticationSpinner />;
  }

  if (!isAuthenticated) {
    return null;
  }

  if (initialLoading) {
    return (
      <div className={styles.excerptsLoadingContainer}>
        <LoadingSpinner text={loadingMessage} />
      </div>
    );
  }

  const renderExcerpts = () => {
    if (status === 'loading' && currentPage === 1) {
      return (
        <div className={styles.excerptsLoadingContainer}>
          <LoadingSpinner text="Loading excerpts..." />
        </div>
      );
    }
    
    if (status === 'failed') {
      return (
        <div className={styles.error}>
          Error: {getErrorMessage(error)}
        </div>
      );
    }
  
    if (status === 'succeeded' && data) {
      if (!data.excerpts || data.excerpts.length === 0) {
        return <div className={styles.noResults}>No results found</div>;
      }
  
      return (
        <div>
          <div className={styles.excerptTableContainer}>
            <ExcerptsTable 
              excerpts={data.excerpts} 
              onSelectExcerpt={handleSelectExcerpt}
              onTagSelect={handleTopicSelect}
            />
            {data.pagination && (
            <Pagination
              currentPage={data.pagination.page}
              totalItems={data.pagination.total}
              itemsPerPage={data.pagination.limit}
              onPageChange={handlePageChange}
            />
          )}
          </div>
        </div>
      );
    }
  
    return null;
  };

  return (
    <>
      <PageHelmet title="Explore" />
      <h1 className={styles.pageTitle}>Explore</h1>
      <div className={styles.sectionContainer}>
        <h2 className={styles.headerTitle}>Filter by Shows</h2>
        <div className={styles.selectedShows}>
          {selectedShows.map(showUuid => {
            const show = getSelectedShowDetails(showUuid);
            if (!show) return null;
            
            return (
              <div key={showUuid} className={styles.selectedShow}>
                <Link 
                  to={`/shows/${show.shows_uuid}`} 
                  className={styles.selectedShowLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ShowImage 
                    src={show.shows_image} 
                    alt={show.title} 
                    size={32}
                  />
                  <span className={styles.selectedShowTitle}>{show.title}</span>
                </Link>
                <button 
                  className={styles.removeShowButton}
                  onClick={() => handleShowSelect(show)}
                >
                  ×
                </button>
              </div>
            );
          })}
          <button 
            className={styles.addShowButton}
            onClick={() => setShowFilterModalOpen(true)}
          >
            +
          </button>
        </div>
      </div>

      <div className={styles.sectionContainer}>
        <h2 className={styles.headerTitle}>Filter by People</h2>
        <div className={styles.selectedPersons}>
          {selectedPersons.map(personUuid => {
            const person = getSelectedPersonDetails(personUuid);
            if (!person) return null;
            
            return (
              <div key={personUuid} className={styles.selectedPerson}>
                <Link 
                  to={`/persons/${person.persons_uuid}`} 
                  className={styles.selectedPersonLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PersonImage
                    src={person.public_image_url} 
                    alt={person.name} 
                    size={32}
                  />
                  <span className={styles.selectedPersonName}>{person.name}</span>
                </Link>
                <button 
                  className={styles.removePersonButton}
                  onClick={() => handlePersonSelect(person)}
                >
                  ×
                </button>
              </div>
            );
          })}
          <button 
            className={styles.addPersonButton}
            onClick={() => setPersonFilterModalOpen(true)}
          >
            +
          </button>
        </div>
      </div>

      <div className={styles.sectionContainer}>
        <h2 className={styles.headerTitle}>Filter by Topics</h2>
        
        {selectedTopics.length > 0 && <div className={styles.selectedTopics}>
          {selectedTopics.map(topicUuid => {
            const topic = getSelectedTopicDetails(topicUuid);
            if (!topic) return null;
            
            return (
              <div key={topicUuid} className={styles.selectedTopic}>
                <span className={styles.selectedTopicName}>{topic.name}</span>
                <button 
                  className={styles.removeTopicButton}
                  onClick={() => handleTopicSelect(topic.name, topic)}
                >
                  ×
                </button>
              </div>
            );
          })}
        </div>}
        {data && data.topics.length == 0 ? <div className={styles.noResults}>No topics found</div> : (
        <TopicsList
          title=""
          topics={(data?.topics || []).filter(topic => 
            !selectedTopics.includes(topic.tags_uuid)
          )}
          loading={status === 'loading'}
          includePagination={false}
          onTopicSelect={handleTopicSelect}
        />
        )}
      </div>
      <div className={styles.excerptsSectionContainer}>
        <h2 className={styles.headerTitle}>
          {selectedShows.length > 0 || selectedTopics.length > 0 || selectedPersons.length > 0 
            ? 'Matching Excerpts ' 
            : 'Excerpts '} 
          ({data && data.pagination ? data.pagination.total : 0})
        </h2>
        {renderExcerpts()}
      </div>
      {showFilterModalOpen && (
          <ShowFilterModal
            onClose={() => setShowFilterModalOpen(false)}
            selectedShows={selectedShows}
            onShowSelect={handleShowSelect}
          />
        )}
      {personFilterModalOpen && (
        <PersonFilterModal
          onClose={() => setPersonFilterModalOpen(false)}
          selectedPersons={selectedPersons}
          onPersonSelect={handlePersonSelect}
          showsUuids={selectedShows}
          selectedTopics={selectedTopics}
        />
      )}
      {renderOverlay()}
    </>
  );
};

export default ExplorePage;
