import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import styles from '../styles/IngestionState.module.css';
import IngestionInfoModal from './IngestionInfoModal';

const IngestionState = ({ totalEpisodes, ingestedEpisodes }) => {
  const [showModal, setShowModal] = useState(false);
  const percentage = totalEpisodes ? (ingestedEpisodes / totalEpisodes) * 100 : 0;
  
  return (
    <>
      <div className={styles.ingestionState}>
        <div className={styles.counts}>
          <div className={styles.countInfo}>
            <span>{ingestedEpisodes} / {totalEpisodes} episodes ingested</span>
            <button 
              className={styles.infoButton}
              onClick={() => setShowModal(true)}
              aria-label="Show ingestion information"
            >
              <FaInfoCircle />
            </button>
          </div>
          <span className={styles.percentage}>({percentage.toFixed(0)}%)</span>
        </div>
        <div className={styles.progressBar}>
          <div 
            className={styles.progress}
            style={{ width: `${percentage}%` }}
          />
        </div>
      </div>
      {showModal && <IngestionInfoModal onClose={() => setShowModal(false)} />}
    </>
  );
};

export default IngestionState;
