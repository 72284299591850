import React from 'react';
import styles from '../styles/IngestionInfoModal.module.css';

const IngestionInfoModal = ({ onClose }) => {
  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>×</button>
        <h2 className={styles.modalTitle}>Episode Ingestion</h2>
        
        <div className={styles.infoContent}>  
          <h3>
            Do you see an episode you want?
          </h3>

          <p>
          Let us know!
          </p>

          <p>
          Visit the Episode's Page and click "Request Episode" to get it prioritized. We're starting with a small set of podcasts and expanding it based on what you ask for.
          </p>

          <h3>
            Episodes are processed in two stages:
          </h3>
          
          <h4>1. Discovery</h4>
          <p>
            When a podcast is first added, we discover all available episodes from its RSS feed. 
            This gives us the total episode count.
          </p>
          
          <h4>2. Ingestion</h4>
          <p>
            Episodes are then downloaded and processed one by one based on priority. This includes:
          </p>
          <ul>
            <li>Downloading audio files</li>
            <li>Transcribing content</li>
            <li>Analyzing topics and content</li>
            <li>Identifying people</li>
          </ul>
          
          <p>
            The progress bar shows how many episodes have been fully processed by Pod Nebula compared to the total number of episodes available.
          </p>
        </div>
      </div>
    </div>
  );
};

export default IngestionInfoModal; 
