import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import FeedbackModal from './FeedbackModal';
import { useSelector } from 'react-redux';

const FeedbackButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (authLoading || !isAuthenticated) return null;

  return (
    <>
      <IconButton
        onClick={() => setIsModalOpen(true)}
        sx={{
          position: 'fixed',
          bottom: { 
            xs: '80px', // Increased bottom margin on mobile to clear the nav bar
            md: 24 
          },
          right: { xs: 16, md: 24 },
          backgroundColor: '#ab56fc',
          color: 'white',
          '&:hover': {
            backgroundColor: '#9540e3',
          },
          zIndex: 1000,
        }}
      >
        <FeedbackIcon />
      </IconButton>
      <FeedbackModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default FeedbackButton;
