import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTagByUUID } from '../api/api';

export const fetchTagByUUID = createAsyncThunk(
  'tags/fetchTagByUUID',
  async (uuid) => {
    const response = await getTagByUUID(uuid);
    return response;
  }
);

const tagInfoSlice = createSlice({
  name: 'tagInfo',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    clearTagData: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTagByUUID.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTagByUUID.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchTagByUUID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearTagData } = tagInfoSlice.actions;
export default tagInfoSlice.reducer;
