import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUserQueue } from '../api/api';
import LoadingSpinner from './LoadingSpinner';
import AuthenticationSpinner from './AuthenticationSpinner';
import PageHelmet from './PageHelmet';
import styles from '../styles/QueuePage.module.css';
import QueueExcerptsTable from './QueueExcerptsTable';
import { FaBookmark, FaComments, FaPlay } from 'react-icons/fa6';
import ExcerptPlayer from './ExcerptPlayer';
import { createPortal } from 'react-dom';

const QueuePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [queueData, setQueueData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedExcerpt, setSelectedExcerpt] = useState(null);
    const [currentExcerptIndex, setCurrentExcerptIndex] = useState(null);
    const [overlayRoot, setOverlayRoot] = useState(null);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);

    const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);

    useEffect(() => {
        setOverlayRoot(document.getElementById('overlay-root'));
    }, []);

    useEffect(() => {
        const fetchQueue = async () => {
            try {
                setLoading(true);
                const response = await getUserQueue({ limit, page });
                setQueueData(response);
                setError(null);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (!authLoading) {
            if (isAuthenticated) {
                fetchQueue();
            } else {
                localStorage.setItem('redirectAfterAuth', location.pathname);
                navigate('/auth?from=' + encodeURIComponent(location.pathname));
            }
        }
    }, [isAuthenticated, authLoading, navigate, location, page, limit]);

    if (authLoading) {
        return <AuthenticationSpinner />;
    }

    if (!isAuthenticated) {
        return null;
    }

    if (error) {
        return <div className={styles.error}>Error: {error}</div>;
    }

    const handleSelectExcerpt = (excerpt, index) => {
        setSelectedExcerpt(excerpt);
        setCurrentExcerptIndex(index);
    };

    const handleCloseExcerpt = () => {
        setSelectedExcerpt(null);
        setCurrentExcerptIndex(null);
    };

    const goToNextExcerpt = () => {
        if (queueData?.queue?.excerpts && currentExcerptIndex < queueData.queue.excerpts.length - 1) {
            const nextIndex = currentExcerptIndex + 1;
            setCurrentExcerptIndex(nextIndex);
            setSelectedExcerpt(queueData.queue.excerpts[nextIndex]);
        }
    };

    const goToPreviousExcerpt = () => {
        if (currentExcerptIndex > 0) {
            const prevIndex = currentExcerptIndex - 1;
            setCurrentExcerptIndex(prevIndex);
            setSelectedExcerpt(queueData.queue.excerpts[prevIndex]);
        }
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const renderOverlay = () => {
        if (!selectedExcerpt || !overlayRoot) return null;

        return createPortal(
            <div 
                className={styles.overlay} 
                onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        handleCloseExcerpt();
                    }
                }}
            >
                <div className={styles.overlayContent}>
                    <ExcerptPlayer
                        excerpt={selectedExcerpt}
                        startPlaying={true}
                        onAudioEnded={goToNextExcerpt}
                        onNextExcerpt={goToNextExcerpt}
                        onPreviousExcerpt={goToPreviousExcerpt}
                    />
                    <button className={styles.closeButton} onClick={handleCloseExcerpt}>
                        ×
                    </button>
                </div>
            </div>,
            overlayRoot
        );
    };

    return (
        <main className={styles.mainContainer}>
            <PageHelmet title="Your Queue" />
            
            <div className={styles.header}>
                <h1 className={styles.title}>Your Queue</h1>
                <p className={styles.description}>
                    Save for later, listen on the go.
                </p>
            </div>
            
            {/* <section className={styles.section}>
                <div className={styles.titleContainer}>
                    <FaBookmark className={styles.queueIcon} />
                    <h2 className={styles.topicTitle}>Episodes</h2>
                </div>
                <p className={styles.topicDescription}>
                    Episodes you've saved for later.
                </p>

                {loading ? (
                    <div className={styles.loadingContainer}>
                        <LoadingSpinner text="Loading your queue..." />
                    </div>
                ) : (
                    <div className={styles.excerptTableContainer}>
                        <div className={styles.noResults}>
                                Your episodes queue is empty
                        </div>
                    </div>
                )}
            </section> */}

            <section className={styles.section}>
                <div className={styles.titleContainer}>
                    <FaBookmark className={styles.discussionsIcon} />
                    <h2 className={styles.excerptsSectionTitle}>
                        Excerpts {queueData?.queue?.excerpts?.length ? `(${queueData.queue.excerpts.length})` : '(0)'}
                    </h2>
                </div>
                <h3 className={styles.topicDescription}>
                    Excerpts you've saved for later.
                </h3>

                {loading ? (
                    <div className={styles.loadingContainer}>
                        <LoadingSpinner text="Loading your queue..." />
                    </div>
                ) : (
                    <div className={styles.excerptTableContainer}>
                        {queueData?.queue?.excerpts && queueData.queue.excerpts.length > 0 ? (
                            <>
                                <button 
                                    className={styles.playButton}
                                    onClick={() => handleSelectExcerpt(queueData.queue.excerpts[0], 0)}
                                >
                                    <FaPlay className={styles.buttonIcon} />
                                    Play Queue
                                </button>
                                <QueueExcerptsTable 
                                    excerpts={queueData.queue.excerpts}
                                    onSelectExcerpt={handleSelectExcerpt}
                                    pagination={queueData.pagination}
                                    onPageChange={handlePageChange}
                                />
                            </>
                        ) : (
                            <div className={styles.noResults}>
                                Your excerpts queue is empty
                            </div>
                        )}
                    </div>
                )}
            </section>
            {renderOverlay()}
        </main>
    );
};

export default QueuePage;
