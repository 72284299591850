import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTrendingTopics } from '../api/api';

export const fetchTrendingTopics = createAsyncThunk(
  'trending/fetchTrendingTopics',
  async (params = {}) => {
    const response = await getTrendingTopics(params);
    return response;
  }
);

const trendingTopicsSlice = createSlice({
  name: 'trending',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrendingTopics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTrendingTopics.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchTrendingTopics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default trendingTopicsSlice.reducer; 