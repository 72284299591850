import React from 'react';
import { FaPlay } from 'react-icons/fa';
import Tags from './Tags';
import styles from '../styles/ExcerptsTable.module.css';

const ExcerptsTable = ({ excerpts, onSelectExcerpt, onTagSelect }) => {
  const handlePersonSelect = (personsUuid) => {
    window.open(`/persons/${personsUuid}`, '_blank');
  };

  const handleShowSelect = (showsUuid) => {
    window.open(`/shows/${showsUuid}`, '_blank');
  };

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <tbody>
            {excerpts.map((excerpt, index) => (
              <tr key={excerpt.excerpt.excerpts_uuid}>
                <td className={styles.imageCell}>
                  <div className={styles.thumbnailContainer} onClick={() => onSelectExcerpt(excerpt, index)}>
                    <img 
                      src={excerpt.episode.episode_image} 
                      alt={excerpt.excerpt.title} 
                      className={styles.thumbnailImage}
                    />
                    <div className={styles.playOverlay}>
                      <FaPlay className={styles.playIcon} />
                    </div>
                  </div>
                </td>
                <td className={styles.contentCell}>
                  <span 
                    className={styles.title}
                    onClick={() => onSelectExcerpt(excerpt, index)}
                  >
                    {excerpt.excerpt.title}
                  </span>
                  <div className={styles.metaInfo}>
                    <span 
                      className={styles.showLink}
                      onClick={() => handleShowSelect(excerpt.show.shows_uuid)}
                    >
                      {excerpt.show.title}
                    </span>
                    {excerpt.guests.length > 0 && (
                      <>
                        <span className={styles.metaDivider}>•</span>
                        <span 
                          className={styles.guestLink}
                          onClick={() => handlePersonSelect(excerpt.guests[0].persons_uuid)}
                        >
                          {excerpt.guests[0].name}
                        </span>
                      </>
                    )}
                  </div>
                  <div className={styles.tagsCell}>
                    <div className={styles.tagsContainer}>
                      <Tags 
                        tags={excerpt.excerpt.tags} 
                        onTagSelect={onTagSelect}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExcerptsTable;
