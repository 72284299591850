import React, { useState, useRef, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { FaPlay } from 'react-icons/fa';
import { IoIosRefresh } from 'react-icons/io';
import { useSwipeable } from 'react-swipeable';
import ExcerptPlayer from './ExcerptPlayer';
import Tags from './Tags';
import styles from '../styles/Feed.module.css';

const ExcerptThumbnail = ({ excerpt, onSelect, isActive }) => {
    const handleTagSelect = (tagName, tag) => {
        window.open(`/explore?tags_uuid=${tag.tags_uuid}`, '_blank');
    };

    const handlePersonSelect = (personsUuid) => {
        window.open(`/persons/${personsUuid}`, '_blank');
    };

    const handleShowSelect = (showsUuid) => {
        window.open(`/shows/${showsUuid}`, '_blank');
    };

    return (
        <div className={`${styles.excerptThumbnail} ${isActive ? styles.active : ''}`}>
            <div className={styles.thumbnailOverlay}>
                <div className={styles.titleContainer}>
                    <h3 className={styles.thumbnailTitle} onClick={onSelect}>{excerpt.excerpt.title}</h3>
                    <p className={styles.showName} onClick={() => {handleShowSelect(excerpt.show.shows_uuid)}}>{excerpt.show.title}</p>
                    {excerpt.guests.length > 0 && <p className={styles.guestNames} onClick={() => {handlePersonSelect(excerpt.guests[0].persons_uuid)}}>{excerpt.guests[0].name}</p>}
                </div>
                
                <div className={styles.thumbnailImageContainer} onClick={onSelect}>
                    <img src={excerpt.episode.episode_image} alt={excerpt.excerpt.title} className={styles.thumbnailImage} />
                    <div className={styles.playButtonOverlay}>
                        <FaPlay className={styles.playIcon} />
                    </div>
                </div>
                
                <div className={styles.tagsContainer}>
                    <Tags tags={excerpt.excerpt.tags} onTagSelect={handleTagSelect} />
                </div>
            </div>
        </div>
    );
};

const Feed = ({ excerpts, onRefresh }) => {
    const [currentExcerptIndex, setCurrentExcerptIndex] = useState(null);
    const [selectedExcerpt, setSelectedExcerpt] = useState(null);
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const scrollContainerRef = useRef(null);
    const [overlayRoot, setOverlayRoot] = useState(null);

    useEffect(() => {
        setOverlayRoot(document.getElementById('overlay-root'));
    }, []);

    useEffect(() => {
        if (excerpts && excerpts.length > 0) {
            if (currentExcerptIndex !== null) {
                const excerpt = excerpts[currentExcerptIndex];
                setSelectedExcerpt(excerpt);
            } else {
                setCurrentExcerptIndex(0);
                setSelectedExcerpt(excerpts[0]);
            }
        }
    }, [currentExcerptIndex, excerpts]);

    const goToPreviousExcerpt = () => {
        setCurrentExcerptIndex(prev => Math.max(prev - 1, 0));
    };

    const goToNextExcerpt = () => {
        setCurrentExcerptIndex(prev => Math.min(prev + 1, excerpts.length - 1));
    };

    const handleSelectExcerpt = (excerpt, index) => {
        setSelectedExcerpt(excerpt);
        setCurrentExcerptIndex(index);
        setIsOverlayOpen(true);
    };

    const handleCloseExcerpt = () => {
        setSelectedExcerpt(null);
        setIsOverlayOpen(false);
    };

    useEffect(() => {
        if (scrollContainerRef.current) {
            const scrollPosition = currentExcerptIndex * scrollContainerRef.current.offsetHeight;
            scrollContainerRef.current.scrollTo({
                top: scrollPosition,
                behavior: 'smooth'
            });
        }
    }, [currentExcerptIndex]);

    const handleScroll = useCallback(() => {
        if (scrollContainerRef.current) {
            const scrollPosition = scrollContainerRef.current.scrollTop;
            const excerptHeight = scrollContainerRef.current.offsetHeight;
            const newIndex = Math.round(scrollPosition / excerptHeight);
            if (newIndex !== currentExcerptIndex) {
                setCurrentExcerptIndex(newIndex);
            }
        }
    }, [currentExcerptIndex]);

    useEffect(() => {
        const container = scrollContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    const renderExcerpts = () => {
        if (!excerpts || excerpts.length === 0) return null;

        return (
            <div className={styles.excerptContainer} ref={scrollContainerRef}>
                {excerpts.map((excerpt, index) => (
                    <ExcerptThumbnail
                        key={excerpt.excerpt.excerpts_uuid}
                        excerpt={excerpt}
                        onSelect={() => handleSelectExcerpt(excerpt, index)}
                        isActive={currentExcerptIndex === index}
                    />
                ))}
                {onRefresh && (
                    <div className={styles.refreshButtonContainer}>
                        <button className={styles.refreshButton} onClick={onRefresh}>
                            <IoIosRefresh />
                            <span>Reload feed</span>
                        </button>
                    </div>
                )}
            </div>
        );
    };

    const renderOverlay = () => {
        if (!selectedExcerpt || !overlayRoot || !isOverlayOpen) return null;

        return createPortal(
            <div 
                className={styles.overlay} 
                onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        handleCloseExcerpt();
                    }
                }}
            >
                <div className={styles.overlayContent}>
                    <ExcerptPlayer
                        key={selectedExcerpt.excerpt.excerpts_uuid}
                        excerpt={selectedExcerpt}
                        startPlaying={true}
                        onAudioEnded={goToNextExcerpt}
                        onNextExcerpt={goToNextExcerpt}
                        onPreviousExcerpt={goToPreviousExcerpt}
                    />
                    <button className={styles.closeButton} onClick={handleCloseExcerpt}>
                        ×
                    </button>
                </div>
            </div>,
            overlayRoot
        );
    };

    const handlers = useSwipeable({
        onSwipedUp: () => goToNextExcerpt(),
        onSwipedDown: () => goToPreviousExcerpt(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    if (!excerpts || excerpts.length === 0) {
        return (
            <div className={styles.noExcerptsContainer}>
                <h2>No excerpts found</h2>
                <button className={styles.refreshButton} onClick={onRefresh}>
                    <IoIosRefresh />
                    <span>Refresh feed</span>
                </button>
            </div>
        );
    }

    return (
        <main className={styles.feedContainer}>
            <div {...handlers} className={styles.excerptContainer} ref={scrollContainerRef}>
                {renderExcerpts()}
            </div>
            {renderOverlay()}
        </main>
    );
};

export default Feed;
