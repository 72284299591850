import React from 'react';
import { FaPlay } from 'react-icons/fa';
import styles from '../styles/RelatedExcerptsTable.module.css';

const RelatedExcerptsTable = ({ excerpts, onSelectExcerpt }) => {
  const sortedExcerpts = [...excerpts].sort((a, b) => {
    const scoreA = a.relationship?.relevancy_score ?? 0;
    const scoreB = b.relationship?.relevancy_score ?? 0;
    return scoreB - scoreA;
  });

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <tbody>
            {sortedExcerpts.map((excerpt, index) => {
              const context = excerpt.relationship?.context;
              
              return (
                <tr key={excerpt.excerpt.excerpts_uuid}>
                  <td className={styles.imageCell}>
                    <div className={styles.thumbnailContainer} onClick={() => onSelectExcerpt(excerpt, index)}>
                      <img 
                        src={excerpt.episode.episode_image} 
                        alt={excerpt.excerpt.title} 
                        className={styles.thumbnailImage}
                      />
                      <div className={styles.playOverlay}>
                        <FaPlay className={styles.playIcon} />
                      </div>
                    </div>
                  </td>
                  <td>
                    <span 
                      className={styles.title}
                      onClick={() => onSelectExcerpt(excerpt, index)}
                    >
                      {excerpt.excerpt.title}
                    </span>
                    <div className={styles.contextContent}>
                      {context || 'No context available'}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RelatedExcerptsTable;
