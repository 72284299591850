import React, { useState } from 'react';
import styles from '../styles/FeaturesPage.module.css';
import PageHelmet from './PageHelmet';

const FeatureCard = ({ title, subtitle, gradientColors }) => (
  <div 
    className={styles.featureCard}
    style={{
      background: `linear-gradient(135deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 100%)`
    }}
  >
    <h2 className={styles.featureTitle}>{title}</h2>
    <p className={styles.featureSubtitle}>{subtitle}</p>
  </div>
);

const FeaturesPage = () => {
  const [activeTab, setActiveTab] = useState('why');

  const whatCards = [
    {
      title: "Explore With Feeds",
      subtitle: "A feed for a show / person / topic. Dive into the episode at any time.",
      gradientColors: ['#9333ea', '#c026d3']
    },
    {
      title: "Skip the Clickbait",
      subtitle: "Explore the complete back catalog of clips relevant to you.",
      gradientColors: ['#4f46e5', '#7c3aed']
    },
    {
      title: '"Pull It Up Jamie"',
      subtitle: "What's mentioned always at your fingertips. The people, places, and things.",
      gradientColors: ['#7c3aed', '#9333ea']
    },
    {
      title: "Learn Anything",
      subtitle: "Create and discover playlists for any topic across podcasts.",
      gradientColors: ['#db2777', '#4f46e5']
    },
    {
      title: "Rich Discovery",
      subtitle: "Find exactly what you're looking for based on the actual conversation.",
      gradientColors: ['#4f46e5', '#db2777']
    },
    {
      title: "Forget 2x Speed",
      subtitle: "Customize your listening experience. From 90 minutes to 9, with a tap.",
      gradientColors: ['#c026d3', '#db2777']
    }
  ];

  const whyCards = [
    {
      title: "Bad UX",
      subtitle: "Why is the experience of listening to a 3-hour podcast the same as a 3-minute song?",
      gradientColors: ['#be123c', '#e11d48']
    },
    {
      title: "Context Matters",
      subtitle: "Hear people in their own words, and within context.",
      gradientColors: ['#7c3aed', '#8b5cf6']
    },
    {
      title: "Avoid Deepfakes",
      subtitle: "Thoughts from verified shows as said by real people.",
      gradientColors: ['#0284c7', '#0ea5e9']
    },
    {
      title: "Gallery of Thoughts",
      subtitle: "When you don't know the right words to search for, take a wander through topics.",
      gradientColors: ['#059669', '#10b981']
    },
    {
      title: "Search is Broken",
      subtitle: "Search the content of the podcast, not SEO keywords.",
      gradientColors: ['#ea6c33', '#d35520']
    },
    {
      title: "Stay Informed",
      subtitle: "Go beyond the surface, listen to meaningful conversations you care about.",
      gradientColors: ['#2563eb', '#4f46e5']
    },
  ];

  return (
    <div className={styles.container}>
      <PageHelmet title="Features" />
      <main className={styles.main}>
        <h1 className={styles.welcome}>The Intelligent Podcast Player</h1>
        <p className={styles.subtitle}>
          A rich world of thoughts trapped in audio, made accessible.
        </p>
        
        <div className={styles.tabs}>
          <button 
            className={`${styles.tab} ${activeTab === 'what' ? styles.activeTab : ''}`}
            onClick={() => setActiveTab('what')}
          >
            What
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'why' ? styles.activeTab : ''}`}
            onClick={() => setActiveTab('why')}
          >
            Why
          </button>
        </div>

        <div className={styles.featuresGrid}>
          {(activeTab === 'what' ? whatCards : whyCards).map((card, index) => (
            <FeatureCard
              key={index}
              title={card.title}
              subtitle={card.subtitle}
              gradientColors={card.gradientColors}
            />
          ))}
        </div>
      </main>
    </div>
  );
};

export default FeaturesPage;
