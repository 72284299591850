import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getTrendingTopicByUUID } from '../api/api';
import LoadingSpinner from './LoadingSpinner';
import AuthenticationSpinner from './AuthenticationSpinner';
import styles from '../styles/TrendingTopicPage.module.css';
import PageHelmet from './PageHelmet';
import { format } from 'timeago.js';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { FaChartLine, FaXTwitter, FaComments } from 'react-icons/fa6';
import RelatedExcerptsTable from './RelatedExcerptsTable';
import ExcerptPlayer from './ExcerptPlayer';
import { createPortal } from 'react-dom';

const TrendingTopicPage = () => {
  const { trending_topics_uuid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);
  
  const [topicData, setTopicData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedExcerpt, setSelectedExcerpt] = useState(null);
  const [currentExcerptIndex, setCurrentExcerptIndex] = useState(null);
  const [overlayRoot, setOverlayRoot] = useState(null);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  useEffect(() => {
    setOverlayRoot(document.getElementById('overlay-root'));
  }, []);

  useEffect(() => {
    if (!authLoading) {
      if (isAuthenticated) {
        const fetchTrendingTopic = async () => {
          try {
            const data = await getTrendingTopicByUUID(trending_topics_uuid);
            setTopicData(data);
            setLoading(false);
          } catch (err) {
            console.error('Error fetching trending topic:', err);
            setError(err.message || 'An error occurred');
            setLoading(false);
          }
        };

        fetchTrendingTopic();
      } else {
        localStorage.setItem('redirectAfterAuth', location.pathname);
        navigate('/auth?from=' + encodeURIComponent(location.pathname));
      }
    }
  }, [trending_topics_uuid, isAuthenticated, authLoading, navigate, location]);

  const handleSelectExcerpt = (excerpt, index) => {
    setSelectedExcerpt(excerpt);
    setCurrentExcerptIndex(index);
  };

  const handleCloseExcerpt = () => {
    setSelectedExcerpt(null);
    setCurrentExcerptIndex(null);
  };

  const goToNextExcerpt = () => {
    if (topicData?.excerpts && currentExcerptIndex < topicData.excerpts.length - 1) {
      const nextIndex = currentExcerptIndex + 1;
      setCurrentExcerptIndex(nextIndex);
      setSelectedExcerpt(topicData.excerpts[nextIndex]);
    }
  };

  const goToPreviousExcerpt = () => {
    if (currentExcerptIndex > 0) {
      const prevIndex = currentExcerptIndex - 1;
      setCurrentExcerptIndex(prevIndex);
      setSelectedExcerpt(topicData.excerpts[prevIndex]);
    }
  };

  const renderOverlay = () => {
    if (!selectedExcerpt || !overlayRoot) return null;

    return createPortal(
      <div 
        className={styles.overlay} 
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            handleCloseExcerpt();
          }
        }}
      >
        <div className={styles.overlayContent}>
          <ExcerptPlayer
            excerpt={selectedExcerpt}
            startPlaying={true}
            onAudioEnded={goToNextExcerpt}
            onNextExcerpt={goToNextExcerpt}
            onPreviousExcerpt={goToPreviousExcerpt}
          />
          <button className={styles.closeButton} onClick={handleCloseExcerpt}>
            ×
          </button>
        </div>
      </div>,
      overlayRoot
    );
  };

  if (authLoading) {
    return <AuthenticationSpinner />;
  }

  if (!isAuthenticated) {
    return null;
  }

  if (loading) {
    return <LoadingSpinner text="Loading trending topic..." />;
  }

  if (error) {
    return <div className={styles.error}>Error: {error}</div>;
  }

  return (
    <main className={styles.mainContainer}>
      <PageHelmet title={topicData.trending_topic.title} />
      <section className={styles.section}>
        <div className={styles.titleContainer}>
          <FaChartLine className={styles.trendingIcon} />
          <a 
            href={topicData.trending_topic.x_link}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.topicTitleContainer}
          >
            <h1 className={styles.topicTitle}>{topicData.trending_topic.title}</h1>
            <FaXTwitter className={styles.hoverIcon} />
          </a>
        </div>
        <div 
          className={`${styles.descriptionContainer} ${isDescriptionExpanded ? styles.expanded : ''}`}
          onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
        >
          <p className={styles.topicDescription}>
            {topicData.trending_topic.description}
          </p>
          <div className={styles.expandButton}>
            {isDescriptionExpanded ? (
              <>
                <span>See Less</span>
                <FaChevronUp />
              </>
            ) : (
              <>
                <span>Show More</span>
                <FaChevronDown />
              </>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          <time className={styles.topicTime}>
            {format(topicData.trending_topic.created_at)}
          </time>
          <a 
            href={topicData.trending_topic.x_link}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.xLink}
          >
            <FaXTwitter className={styles.xIcon} />
            Source
          </a>
        </div>
      </section>

      <section className={styles.section}>
        <div className={styles.titleContainer}>
          <FaComments className={styles.discussionsIcon} />
          <h2 className={styles.excerptsSectionTitle}>
            Related Discussions ({topicData.excerpts?.length || 0})
          </h2>
        </div>
        <h3 className={styles.topicDescription}>
          Get broad context on what's trending.
        </h3>
        <div className={styles.excerptTableContainer}>
          {topicData.excerpts?.length > 0 ? (
            <RelatedExcerptsTable 
              excerpts={topicData.excerpts} 
              onSelectExcerpt={handleSelectExcerpt}
            />
          ) : (
            <div className={styles.noResults}>No excerpts found</div>
          )}
        </div>
      </section>
      {renderOverlay()}
    </main>
  );
};

export default TrendingTopicPage;
