import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExcerptByUUID, clearExcerptData } from '../store/excerptInfoSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthenticationSpinner from './AuthenticationSpinner';
import LoadingSpinner from './LoadingSpinner';
import ExcerptPlayer from './ExcerptPlayer';

const ExcerptPage = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const { data: excerptData, loading, error } = useSelector(state => state.excerptInfo);
  const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!authLoading) {
        if (isAuthenticated && uuid) {
          dispatch(fetchExcerptByUUID(uuid));
        } else {
            localStorage.setItem('redirectAfterAuth', location.pathname);
            navigate('/auth?from=' + encodeURIComponent(location.pathname));
        }
    }

    return () => {
      dispatch(clearExcerptData());
    };
}, [uuid, dispatch, isAuthenticated, authLoading, navigate, location]);

  if (authLoading) {
    return <AuthenticationSpinner />;
  }

  if (!isAuthenticated) {
    return null;
  }

  if (loading) {
    return <LoadingSpinner text="Loading excerpt..." />;
  }

  if (error) return <div>Error: {error}</div>;

  return <ExcerptPlayer excerpt={excerptData} />;
};

export default ExcerptPage;
