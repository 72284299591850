import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaCalendar, FaShareSquare, FaPlus, FaCheck, FaSpinner } from 'react-icons/fa';
import EntityList from './EntityList';
import styles from '../styles/ExcerptPlayer.module.css';
import Person from './Person';
import PageHelmet from './PageHelmet';
import MobileExcerptItem from './MobileExcerptItem';
import ShowImage from './ShowImage';
import EpisodeSponsor from './EpisodeSponsor';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EpisodeImageContainer from './EpisodeImageContainer';
import { addToQueue } from '../api/api';

const ExcerptPlayer = ({ excerpt, startPlaying = false, onAudioEnded, onNextExcerpt, onPreviousExcerpt }) => {
  const [seekTo, setSeekTo] = useState(null);
  const [isPlaying, setIsPlaying] = useState(startPlaying);
  const navigate = useNavigate();
  const [playerTime, setPlayerTime] = useState(0);
  const [isInQueue, setIsInQueue] = useState(false);
  const [isAddingToQueue, setIsAddingToQueue] = useState(false);

  const handleTimeUpdate = useCallback((time) => {
    setPlayerTime(time);
  }, []);

  useEffect(() => {
    setIsPlaying(startPlaying);
  }, [excerpt, startPlaying]);

  const handleDiveIntoEpisode = useCallback((e) => {
    const timeOffset = Math.floor(playerTime + excerpt.excerpt.timestamp_start_seconds);
    const url = `/episodes/${excerpt.episode?.episodes_uuid}?time_offset=${timeOffset}`;
    window.open(url, '_blank');
    e.preventDefault();
  }, [playerTime, excerpt]);

  const handleShareClick = () => {
    const currentUrl = window.location.origin + `/excerpts/${excerpt.excerpt.excerpts_uuid}`;
    navigator.clipboard.writeText(currentUrl).then(() => {
      toast.success('Excerpt Link copied to clipboard', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        className: styles.toastSuccess,
      });
    });
  };

  const handleTagSelect = (tagName, tag) => {
    window.open(`/feed?tags_uuid=${tag.tags_uuid}`, '_blank');
};

  const handleAddToQueue = async () => {
    setIsAddingToQueue(true);
    try {
      await addToQueue(excerpt.excerpt.excerpts_uuid);
      setIsInQueue(true);
      toast.success('Added to queue successfully', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        className: styles.toastSuccess,
      });
    } catch (error) {
      toast.error('Failed to add to queue', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        className: styles.toastError,
      });
    } finally {
      setIsAddingToQueue(false);
    }
  };

  useEffect(() => {
    setIsInQueue(false);
    setIsAddingToQueue(false);
  }, [excerpt]);

  if (!excerpt) return <div>No excerpt found</div>;

  return (
    <div className={`bg-gray-900 text-gray-100 font-sans ${styles.playerContainer}`}>
      <PageHelmet title={excerpt.excerpt.title} />
      <ToastContainer
        toastClassName={styles.customToast}
        progressClassName={styles.customProgress}
      />
      <main className="container mx-auto px-4 py-8 md:py-16">
        <div className={styles.playerMainContent}>
          <div className={styles.episodeHeader}>
            <div className={styles.episodeInfo}>
              <h2 className={styles.excerptTitle}>{excerpt.excerpt.title}</h2>
              <h3 className={styles.episodeTitle}>
                <Link 
                  to={`/episodes/${excerpt.episode?.episodes_uuid}?time_offset=${Math.floor(playerTime + excerpt.excerpt.timestamp_start_seconds)}`}
                  onClick={handleDiveIntoEpisode}
                  className={styles.episodeTitleButton}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {excerpt.episode?.title}
                </Link>
              </h3>
              <h4 className={styles.showTitle}>
                <Link to={`/shows/${excerpt.show?.shows_uuid}`} className={styles.showTitleLink}>
                  {excerpt.show?.title}
                </Link>
              </h4>
              {excerpt.episode?.published_date && (
                <p className={styles.publishedDate}>
                  <FaCalendar className={styles.calendarIcon} />
                  {new Date(excerpt.episode.published_date).toLocaleDateString()}
                </p>
              )}
              <div className={styles.personList}>
                <div className={styles.personListItems}>
                  <Link to={`/shows/${excerpt.show?.shows_uuid}`}>
                    <ShowImage 
                      src={excerpt.show?.shows_image} 
                      alt={excerpt.show?.title} 
                      size={40}
                    />
                  </Link>
                  {excerpt.guests && excerpt.guests.length > 0 && excerpt.guests.map((person) => (
                    <Person key={person.persons_uuid} person={person} size={40} />
                  ))}
                </div>
                <button 
                  className={styles.shareButton}
                  onClick={handleShareClick}
                >
                  <FaShareSquare className={styles.buttonIcon} />
                  Share
                </button>
              </div>
            </div>
            <div className={styles.episodeImageSection}>
              <EpisodeImageContainer 
                episode={excerpt.episode}
                show={excerpt.show}
                currentlyPlayingExcerpt={excerpt.excerpt}
                onTimeUpdate={handleTimeUpdate}
                isPlaying={isPlaying}
                onNextExcerpt={onNextExcerpt}
                onPreviousExcerpt={onPreviousExcerpt}
                onDiveIntoEpisode={handleDiveIntoEpisode}
                onAudioEnded={onAudioEnded}
                showShareButton={false}
              />
              <button 
                className={`${styles.queueButtonBelowImage} ${isInQueue ? styles.queueButtonSuccess : ''}`}
                onClick={handleAddToQueue}
                disabled={isInQueue || isAddingToQueue}
              >
                {isAddingToQueue ? (
                  <FaSpinner className={`${styles.buttonIcon} ${styles.spinningIcon}`} />
                ) : isInQueue ? (
                  <FaCheck className={styles.buttonIcon} />
                ) : (
                  <FaPlus className={styles.buttonIcon} />
                )}
                {isAddingToQueue ? 'Adding to Queue...' : isInQueue ? 'Added to Queue' : 'Add to Queue'}
              </button>
            </div>
          </div>

          {excerpt.excerpt.entities && excerpt.excerpt.entities.length > 0 && (
            <div className={styles.currentlyPlayingContainer}>
              <h4 className={styles.currentlyPlayingTitle}>Pull It Up Jamie</h4> 
              <EntityList entities={excerpt.excerpt.entities} />
            </div>
          )}

          <div className={styles.currentlyPlayingContainer}>
            <h4 className={styles.currentlyPlayingTitle}>Excerpt Description</h4> 
            <MobileExcerptItem 
              key={excerpt.excerpt.excerpts_uuid}
              excerpt={excerpt.excerpt} 
              onTagSelect={handleTagSelect}
              onListenExcerpt={() => {}}
            />
          </div>

          {(excerpt !== null && excerpt.episode.sponsors.length > 0) && (
            <div className={styles.currentlyPlayingContainer}>
              <h4 className={styles.sponsorsTitle}>Support this Podcast</h4> 
              <EpisodeSponsor sponsors={excerpt.episode.sponsors} />
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default ExcerptPlayer;
