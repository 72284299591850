import React from 'react';
import WaitlistBox from './WaitlistBox';
import styles from '../styles/AboutPage.module.css';
import PageHelmet from './PageHelmet';

const AboutPage = () => {
  return (
    <div className={styles.container}>
      <PageHelmet title="About" />
      <main className={styles.main}>
        <WaitlistBox />
        <h1 className={styles.title}>About Pod Nebula</h1>
        <div className={styles.content}>
          <p className={styles.paragraph}>
            We started by winning the first AITX Hackathon in Austin Texas.
          </p>
          <p className={styles.paragraph}>
            We're still in stealth mode. Connect with us at{' '}
            <a href="mailto:info@podnebula.com" className={styles.link}>
              info@podnebula.com
            </a>
          </p>
        </div>
      </main>
    </div>
  );
};

export default AboutPage;
