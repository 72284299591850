import React, { useState, useEffect } from 'react';
import { getAllPersons } from '../api/api';
import LoadingSpinner from './LoadingSpinner';
import styles from '../styles/PersonFilterModal.module.css';
import PersonImage from './PersonImage';

const PersonFilterModal = ({ onClose, selectedPersons, onPersonSelect, showsUuids, selectedTopics }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [persons, setPersons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const loadPersons = async (page = 1, query = '', shows_uuids = [], topics = []) => {
    try {
      setLoading(true);
      setError(null);
      const response = await getAllPersons({
        query,
        page,
        limit: 20,
        shows_uuids: showsUuids,
        topics: selectedTopics
      });
      
      const newPersons = response?.persons || [];
      
      if (page === 1) {
        setPersons(newPersons);
      } else {
        setPersons(prev => {
          const uniquePersons = [...prev];
          newPersons.forEach(person => {
            if (!uniquePersons.some(p => p.persons_uuid === person.persons_uuid)) {
              uniquePersons.push(person);
            }
          });
          return uniquePersons;
        });
      }
      
      setTotalCount(response?.total_count || 0);
      setHasMore(newPersons.length === 20);
      setCurrentPage(page);
    } catch (err) {
      setError(err.message || 'Failed to load persons');
      setPersons([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadPersons(1, searchQuery);
  }, [searchQuery, selectedTopics]);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && !loading && hasMore) {
      loadPersons(currentPage + 1, searchQuery);
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>×</button>
        <h2 className={styles.modalTitle}>People</h2>
        
        <div className={styles.searchContainer}>
          <input
            type="text"
            placeholder="Search people..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className={styles.searchInput}
          />
        </div>

        <div className={styles.listContainer} onScroll={handleScroll}>
          {error && <div className={styles.error}>{error}</div>}
          
          <div className={styles.personsList}>
            {persons && persons.map(person => (
              <div
                key={person.persons_uuid}
                className={`${styles.item} ${
                  selectedPersons.includes(person.persons_uuid) ? styles.selected : ''
                }`}
                onClick={() => onPersonSelect(person)}
              >
                <div className={styles.itemContent}>
                  <PersonImage
                    src={person.public_image_url} 
                    alt={person.name} 
                    size={32}
                  />
                  <div className={styles.personInfo}>
                    <span className={styles.personName}>{person.name}</span>
                  </div>
                  {selectedPersons.includes(person.persons_uuid) && (
                    <span className={styles.checkmark}>✓</span>
                  )}
                </div>
              </div>
            ))}
          </div>
          
          {loading && (
            <LoadingSpinner 
              size={30} 
              text={searchQuery ? 'Searching people...' : 'Loading people...'}
            />
          )}
        </div>

        <div className={styles.modalFooter}>
          <span className={styles.resultCount}>
            {totalCount} {totalCount === 1 ? 'person' : 'people'} found
          </span>
        </div>
      </div>
    </div>
  );
};

export default PersonFilterModal;
