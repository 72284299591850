import React from 'react';
import { FaPlay } from 'react-icons/fa';
import styles from '../styles/QueueExcerptsTable.module.css';
import Pagination from './Pagination';

const QueueExcerptsTable = ({ excerpts, onSelectExcerpt, pagination, onPageChange }) => {
  const handlePersonSelect = (personsUuid) => {
    window.open(`/persons/${personsUuid}`, '_blank');
  };

  const handleShowSelect = (showsUuid) => {
    window.open(`/shows/${showsUuid}`, '_blank');
  };

  const sortedExcerpts = [...excerpts].sort((a, b) => {
    const dateA = new Date(a.added_datetime_utc_iso_str);
    const dateB = new Date(b.added_datetime_utc_iso_str);
    return dateB - dateA;
  });

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <tbody>
            {sortedExcerpts.map((excerpt, index) => {
              const context = excerpt.relationship?.context;
              
              return (
                <tr key={excerpt.excerpt.excerpts_uuid}>
                  <td className={styles.imageCell}>
                    <div className={styles.thumbnailContainer} onClick={() => onSelectExcerpt(excerpt, index)}>
                      <img 
                        src={excerpt.episode.episode_image} 
                        alt={excerpt.excerpt.title} 
                        className={styles.thumbnailImage}
                      />
                      <div className={styles.playOverlay}>
                        <FaPlay className={styles.playIcon} />
                      </div>
                    </div>
                  </td>
                  <td className={styles.contentCell}>
                    <span 
                      className={styles.title}
                      onClick={() => onSelectExcerpt(excerpt, index)}
                    >
                      {excerpt.excerpt.title}
                    </span>
                    <div className={styles.metaInfo}>
                      <span 
                        className={styles.showLink}
                        onClick={() => handleShowSelect(excerpt.show.shows_uuid)}
                      >
                        {excerpt.show.title}
                      </span>
                      {excerpt.guests.length > 0 && (
                        <div className={styles.guestInfo}>
                          <span 
                            className={styles.guestLink}
                            onClick={() => handlePersonSelect(excerpt.guests[0].persons_uuid)}
                          >
                            {excerpt.guests[0].name}
                          </span>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {pagination && (
        <Pagination
          currentPage={pagination.page}
          totalItems={pagination.total}
          itemsPerPage={pagination.limit}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};

export default QueueExcerptsTable;
