import React, { useState } from 'react';
import {
  TextField,
  Alert,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { submitFeedback } from '../api/api';
import styles from '../styles/FeedbackModal.module.css';

const FeedbackModal = ({ open, onClose }) => {
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const location = useLocation();

  if (!open) return null;

  const handleSubmit = async () => {
    if (!message.trim()) return;

    setIsSubmitting(true);
    setError(null);
    
    try {
      await submitFeedback({
        feedback: message,
        currentUrl: window.location.href,
      });
      setSuccess(true);
      setMessage('');
      setTimeout(() => {
        onClose();
        setSuccess(false);
      }, 2000);
    } catch (err) {
      setError('Failed to submit feedback. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.dialog} onClick={onClose}>
      <div className={styles.dialogContent} onClick={(e) => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>×</button>
        <h2 className={styles.dialogTitle}>
          Share Feedback
        </h2>
        {error && (
          <Alert 
            severity="error" 
            className={styles.alert}
          >
            {error}
          </Alert>
        )}
        {success && (
          <Alert 
            severity="success" 
            className={styles.alert}
          >
            Thank you for sharing your feedback!
          </Alert>
        )}
        <TextField
          autoFocus
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          placeholder="Share your thoughts, request a show, etc..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className={styles.textField}
        />
        <div className={styles.dialogActions}>
          <button 
            onClick={onClose}
            className={styles.cancelButton}
          >
            Cancel
          </button>
          <button 
            onClick={handleSubmit}
            disabled={isSubmitting || !message.trim()}
            className={styles.submitButton}
          >
            {isSubmitting ? 'Sending...' : 'Share Feedback'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
