import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { User } from 'lucide-react';
import { getUserPreferences } from '../api/api';
import { clearUser } from '../store/authSlice';
import { auth } from '../firebase';
import AuthenticationSpinner from './AuthenticationSpinner';
import LoadingSpinner from './LoadingSpinner';
import PageHelmet from './PageHelmet';
import styles from '../styles/UserProfile.module.css';

function UserProfile() {
  const { user, isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);
  const [preferences, setPreferences] = useState(null);
  const [error, setError] = useState(null);
  const [isLoadingPreferences, setIsLoadingPreferences] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authLoading) {
      if (isAuthenticated) {
        fetchUserPreferences();
      } else {
        navigate('/auth');
      }
    }
  }, [isAuthenticated, authLoading, navigate]);

  const fetchUserPreferences = async () => {
    setIsLoadingPreferences(true);
    try {
      const data = await getUserPreferences();
      setPreferences(data);
    } catch (error) {
      setError(error.message || 'An error occurred while fetching user preferences');
    } finally {
      setIsLoadingPreferences(false);
    }
  };

  const handleSignOut = () => {
    auth.signOut().then(() => {
      dispatch(clearUser());
      navigate('/auth');
    });
  };

  if (authLoading) {
    return <AuthenticationSpinner />;
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <main className={styles.container}>
      <PageHelmet title="Profile" />
      <h1 className={styles.title}>User Profile</h1>
      <div className={styles.profileCard}>
        {user.photoURL ? (
          <img src={user.photoURL} alt="Profile" className={styles.profileImage} />
        ) : (
          <User className={styles.userIcon} />
        )}
        <p className={styles.infoText}>
          <span className={styles.infoLabel}>Name:</span> {user.displayName || 'N/A'}
        </p>
        <p className={styles.infoText}>
          <span className={styles.infoLabel}>Email:</span> {user.email}
        </p>
        <button
          onClick={handleSignOut}
          className={styles.signOutButton}
        >
          Sign Out
        </button>
      </div>
    </main>
  );
}

export default UserProfile;
