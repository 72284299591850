import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchTrendingTopics } from '../store/trendingTopicsSlice';
import { FaChartLine } from 'react-icons/fa';
import { format } from 'timeago.js';
import LoadingSpinner from './LoadingSpinner';
import AuthenticationSpinner from './AuthenticationSpinner';
import PageHelmet from './PageHelmet';
import styles from '../styles/TrendingTopicsPage.module.css';

const TrendingTopicsPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { data: trendingData, loading: trendingLoading, error: trendingError } = useSelector((state) => state.trending);
    const { isAuthenticated, isLoading: authLoading } = useSelector((state) => state.auth);

    useEffect(() => {
        if (!authLoading) {
            if (isAuthenticated) {
                dispatch(fetchTrendingTopics({ limit: 30 }));
            } else {
                localStorage.setItem('redirectAfterAuth', location.pathname);
                navigate('/auth?from=' + encodeURIComponent(location.pathname));
            }
        }
    }, [dispatch, isAuthenticated, authLoading, navigate, location]);

    const handleTopicClick = (event, topicUuid) => {
        if (event.ctrlKey || event.metaKey || event.button === 1) {
            return;
        }
        event.preventDefault();
        navigate(`/trending/${topicUuid}`);
    };

    if (authLoading) {
        return <AuthenticationSpinner />;
    }

    if (!isAuthenticated) {
        return null;
    }

    if (trendingError) {
        return <div className={styles.error}>Error: {trendingError}</div>;
    }

    return (
        <main className={styles.mainContainer}>
            <PageHelmet title="Trending Topics" />
            
            <div className={styles.header}>
                <h1 className={styles.title}>Trending on 𝕏</h1>
                <p className={styles.description}>
                    Get broad context for what's happening.
                </p>
            </div>

            {trendingLoading ? (
                <div className={styles.loadingContainer}>
                    <LoadingSpinner text="Loading trending topics..." />
                </div>
            ) : (
                <div className={styles.topicsGrid}>
                    {trendingData?.topics?.map((topic) => (
                        <a
                            href={`/trending/${topic.trending_topics_uuid}`}
                            key={topic.trending_topics_uuid}
                            className={styles.topicCard}
                            onClick={(e) => handleTopicClick(e, topic.trending_topics_uuid)}
                        >
                            <div className={styles.topicContent}>
                            <time className={styles.topicTime}>
                                    {format(topic.created_at)}
                                </time>
                                <div className={styles.topicHeader}>
                                    <FaChartLine className={styles.topicIcon} />
                                    <h2 className={styles.topicTitle}>
                                        {topic.title}
                                    </h2>
                                </div>
                                <p className={styles.topicDescription}>
                                    {topic.description}
                                </p>
                            </div>
                        </a>
                    ))}
                </div>
            )}
        </main>
    );
};

export default TrendingTopicsPage;
